export const relatedProducts = (reference, products) => {
  if (reference?.tipo === "Oferta") {
    return []; // No se muestran sugerencias si el tipo es "Oferta"
  }

  const superficieReference =
    reference?.superficies.metrosCubiertos +
    reference?.superficies.metrosSemicubiertos;

  const productSimilar = products
    .map((item) => {
      const superficieItem =
        item.superficies.metrosCubiertos + item.superficies.metrosSemicubiertos;

      if (item.id !== reference?.id && item.tipo === reference?.tipo) {
        return {
          ...item,
          similitud:
            100 -
            (Math.abs(superficieReference - superficieItem) /
              ((superficieReference + superficieItem) / 2)) *
              100,
        };
      }
      return null; // Ignorar si no cumple las condiciones
    })
    .filter(Boolean) // Filtra valores nulos
    .sort((a, b) => b.similitud - a.similitud);

  const productDifferentPlantas = products
    .map((item) => {
      const superficieItem =
        item.superficies.metrosCubiertos + item.superficies.metrosSemicubiertos;

      if (
        item.id !== reference?.id &&
        item.tipo === reference?.tipo &&
        item.cantidadDePlantas !== reference?.cantidadDePlantas
      ) {
        return {
          ...item,
          similitud:
            100 -
            (Math.abs(superficieReference - superficieItem) /
              ((superficieReference + superficieItem) / 2)) *
              100,
        };
      }
      return null; // Ignorar si no cumple las condiciones
    })
    .filter(Boolean) // Filtra valores nulos
    .sort((a, b) => b.similitud - a.similitud);

  return [
    productSimilar.slice(0, 1)[0],
    productSimilar.slice(1, 2)[0],
    productDifferentPlantas.slice(0, 1)[0],
  ];
};
