import React, { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Helmet } from "react-helmet";
import { goTop } from "../../helpers/goTop";
import { Layout } from "../../components/Layout/Layout";
import feeds from "../../data/FeedContenido.json";
import feedsOferta from "../../data/FeedContenidoOferta.json";
import modelosJSON from "../../data/Products/viviendas.json";
import "./Home.css";
import moment from "moment";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import { scroller } from "../../helpers/scroller";
import Feed from "../../components/Feed/Feed";
import FeedsOferta from "../../components/Feed/FeedOferta";
import CardItem from "../../components/CardItem/CardItem";
moment().format();

const Home = () => {
  const [modelos, setModelos] = useState(modelosJSON);
  const [modelosEnOferta, setModelosEnOferta] = useState([]);
  const [otrosModelos, setOtrosModelos] = useState([]);

  useEffect(() => {
    goTop();
  }, []);

  const [timeLeft, setTimeLeft] = useState();
  const end = moment().endOf("day");
  const endMonth = moment().endOf("month").format("DD/MM/yyyy");

  useEffect(() => {
    setInterval(() => {
      setTimeLeft(moment(end.diff(moment())).format("HH:mm:ss"));
    }, 1000);
  }, []);

  useEffect(() => {
    // Filtra los modelos en oferta y los últimos 3
    const enOferta = modelosJSON.filter((modelo) => modelo.esOferta);
    const ultimosTresEnOferta = enOferta.slice(-3);
    const noEnOferta = modelosJSON.filter((modelo) => !modelo.esOferta);

    setModelosEnOferta(ultimosTresEnOferta);
    setOtrosModelos([...noEnOferta, ...enOferta.slice(0, -3)]);
  }, []);

  const handleClickSistemaConstructivo = () => {
    scroller(".contenedor-empresa__quienes-somos__seccion3", 0);
  };

  const handleClickOfertas = () => {
    scroller("#cab", 80);
  };

  return (
    <>
      <Helmet>
        <title>Viviendas La Esperanza</title>
      </Helmet>
      <Carousel
        showArrows={false}
        emulateTouch={true}
        showThumbs={false}
        showStatus={false}
        infiniteLoop={true}
        swipeable={true}
        useKeyboardArrows={true}
        autoPlay={true}
        interval={5000}
        transitionTime={800}
        className="mainSlider">
        {[
          { link: "/", src: "/slider/1", onClick: handleClickOfertas},
          { link: "/contacto", src: "/slider/3" },
          { link: "/showroom", src: "/slider/4" },
          { link: "/contacto", src: "/slider/6" },
        ].map((item, index) => (
          <div className="slider_item" key={index}>
            <Link
              className="btnSlider"
              to={item.link}
              onClick={item.onClick || null}>
              <video autoPlay loop muted playsInline aria-label={`Slide ${index + 1}`}>
                <source
                  src={(`${item.src}_mobile.mp4`, `${item.src}.mp4`)}
                  type="video/mp4"
                />
              </video>
            </Link>
          </div>
        ))}
      </Carousel>
      <Layout>
        <main>
          <Fade bottom cascade>
            <div className="contenedor-header">
              <div className="contenedor-header__titulo1">
                <span className="contenedor-header__titulo1__text">
                  <strong>OFERTA</strong>
                  <br />
                  EL MEJOR PRECIO DEL MERCADO
                </span>
              </div>
              <div className="contenedor-header__descripcion1">
                <p className="contenedor-header__descripcion__text">FINANCIAMIENTO CON UNA ENTREGA MÍNIMA DE 40%</p>
              </div>
            </div>
          </Fade>
          {feedsOferta.map((feed, key) => {
            return (
              <FeedsOferta
                myKey={key}
                titulo={feed.titulo}
                leyenda={feed.leyenda}
                leyendaExpansion={feed.leyendaExpansion}
                filtro={feed.filtro}
                id={feed.id}>
                {modelosEnOferta.map((modelo) => {
                  return (
                    modelo.tipo === feed.filtro[0] &&
                    (feed.filtro[1]
                      ? modelo.cantidadDePlantas === feed.filtro[1]
                      : modelo.tipo === feed.filtro[0]) && (
                      <CardItem key={key} modelo={modelo} />
                    )
                  );
                })}
              </FeedsOferta>
            );
          })}
        </main>
      </Layout>

        <div className="contenedor-bonificacion">
          <picture>
            <source
              srcSet={
                window.innerWidth <= 800 
                  ? "/images/bonificacion-movil.webp" 
                  : "/images/bonificacion.webp"
              }
              type="image/webp"
              className="bonificacion"
            />
            <img 
              src="/images/bonificacion.webp" 
              alt="Descripción de la imagen"
              className="bonificacion"
            />
          </picture>
        </div>
        
        <Layout>
        <Fade bottom cascade>
          <div className="contenedor-header">
            <div
              style={{ borderRadius: "1.6vw", padding: "1vw" }}
              className="contenedor-header__titulo2">
              <span className="contenedor-header__titulo2__text">
                ¡Conocé nuestros{window.innerWidth <= 480 ? <br /> : " "}
                <strong>nuevos modelos!</strong>
              </span>
            </div>
            <div className="contenedor-header__descripcion">
              <span className="contenedor-header__descripcion__text">
                ¡Diseñados por los mejores arquitectos
                <br /> del país, <strong>especialmente para vos!</strong>
              </span>
            </div>
          </div>
        </Fade>
        {feeds.map((feed, key) => {
          return (
            <Feed
              myKey={key}
              titulo={feed.titulo}
              leyenda={feed.leyenda}
              leyendaExpansion={feed.leyendaExpansion}
              filtro={feed.filtro}
              id={feed.id}>
              {modelos.map((modelo, key) => {
                return (
                  modelo.tipo === feed.filtro[0] &&
                  (feed.filtro[1]
                    ? modelo.cantidadDePlantas === feed.filtro[1]
                    : modelo.tipo === feed.filtro[0]) && (
                    <CardItem key={key} modelo={modelo} />
                  )
                );
              })}
            </Feed>
          );
        })}
      </Layout>
      <Link onClick={handleClickSistemaConstructivo} to="/empresa">
        <video
          className="video__construccion"
          autoPlay
          loop
          width="100%"
          muted
          playsInline
          loading="lazy">
          <source
            loading="lazy"
            src={
              window.innerWidth <= 1024
                ? "/videos/home/video-construccion_mobile.mp4"
                : "/videos/home/video-construccion.mp4"
            }
            type="video/mp4"
          />
        </video>
      </Link>
    </>
  );
};

export { Home };
